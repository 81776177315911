import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/FAQ": [5],
		"/bookmarks": [6],
		"/cookie-policy": [7],
		"/games": [8],
		"/games/[category]": [9],
		"/games/[category]/[gameId]": [10,[],[2]],
		"/google-search": [11],
		"/horoscope": [12],
		"/news": [13],
		"/news/[category]": [14],
		"/news/[category]/[postId]": [15,[],[3]],
		"/search-results": [16],
		"/speed-test": [17],
		"/sports": [18],
		"/terms-of-service": [19],
		"/weather": [20],
		"/wiki-bytes": [21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';